(function($){
    var player = null;

    function domina_refresh_size_queries() {
        var classes = [];
        var scrollbarwidth = domina_getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        window.is_phone = (window_width < 768);
        window.is_mobile = (window_width < 992);
        window.is_tablet_portrait = (window_width >= 768 && window_width < 992);
        window.is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        window.is_tablet = is_tablet_portrait || is_tablet_landscape;
        window.is_desktop = (window_width >= 992);
        window.is_desktop_large = (window_width >= 1200);

        if (window.is_phone) {
            classes.push('mq_phone');
        }
        if (window.is_mobile) {
            classes.push('mq_mobile');
        }
        if (window.is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (window.is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (window.is_tablet) {
            classes.push('mq_tablet');
        }
        if (window.is_desktop) {
            classes.push('mq_desktop');
        }
        if (window.is_desktop_large) {
            classes.push('mq_desktop_large');
        }


        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');

        $('html').addClass(classes.join(' '));

    }

    function domina_getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function domina_seo_position() {
        $('.seobar-wrapper').appendTo($('#seobar_content'));
    }

    function play_video() {
        if (player === null) {
            window.console && console.log('Player not initialized yet');
            return;
        }
        player.play();
        showVideo();
        var video_height = $(player.elements.container).height();
        $('#slideshow_wrapper').css({
            height: video_height,
            minHeight: 0
        });
    }

    function showVideo() {
        $('#slideshow_wrapper').addClass('playing');
        $('.slideshow').slick('slickPause');
    }

    function hideVideo() {
        $('#slideshow_wrapper').removeClass('playing');
        $('#slideshow_wrapper').removeAttr('style');
        $('.slideshow').slick('slickPlay');
    }

    function domina_video_configuration(id){
        var controls_video = $('.controls .player i');

        showVideo();

        if (player === null) {
            $('#player').attr('data-plyr-embed-id', id);
            var error = 0;

            player = new Plyr('#player', {
                controls: [
                    'play-large',
                    'play',
                    'progress',
                    'current-time',
                    'mute',
                    'volume',
                    'captions',
                    'airplay',
                    'fullscreen'
                ],
                quality: {
                    default: 'auto',
                },
                storage: {
                    enabled: false
                },
                volume: 0.5,
                debug: false
            });

            player.on('ready', function() {
                play_video();
            });

            //Fix IOS + chrome on youtube - play doesn't work with this combo. So we just remove the slideshow layer on click.
            //Fix it would require to drop the video load on click hugely worsening the page speed (about 15mb more).
            //And anyway WTF https://www.zdnet.com/article/which-browser-is-most-popular-on-each-major-operating-system/
            player.on('statechange', function(e){
                var eventCode = e.detail.code;
                //we have two event code -1 (unstarted) only when the browser consider illegitimate the video play (detected as "auto play")
                //more info https://developers.google.com/youtube/iframe_api_reference
                if(eventCode === -1){
                    error++;
                    if(error > 1){
                        player.pause();
                    }
                }
            });

            player.on('ended', function(){
                player.stop();
                hideVideo();
            });

            controls_video.on('click', function () {
                if($(this).hasClass('fa-pause-circle')) {
                    controls_video.removeClass('fa-pause-circle');
                    controls_video.addClass('fa-play-circle');
                    player.pause();
                } else {
                    controls_video.removeClass('fa-play-circle');
                    controls_video.addClass('fa-pause-circle');
                    player.play();
                }
            });

            $('.volume').on('click', function () {
                var icon = $('.volume .fas');
                var up = 'fa-volume-up';
                var down = 'fa-volume-down';
                var mute = 'fa-volume-mute';

                if (icon.hasClass(down)) {
                    player.volume = 1;
                    icon.removeClass(down);
                    icon.addClass(up);
                } else if (icon.hasClass(up)) {
                    player.volume = 0;
                    icon.removeClass(up);
                    icon.addClass(mute);
                } else {
                    player.volume = 0.5;
                    icon.removeClass(mute);
                    icon.addClass(down);
                }
            });

            $('.controls .stop').on('click', function () {
                player.stop();
                hideVideo();
            });
        } else{
            play_video();
        }
    }

    function domina_slideshow(){
        var slideshow_container = $('#slideshow_wrapper');
        if(slideshow_container.length > 0 && !$('html').hasClass('blog')){
            $('.slideshow').not('.slick-initialized').slick({
                rows: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: '4000',
                autoplaySpeed: '4500',
                fade: true,
                arrows: true,
                dots: true,
                autoplay: true,
                pauseOnHover: false,
                waitForAnimate: false,
                dotsClass: 'slick-dots inverse',
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });

            $('.slideshow').on('beforeChange', function(event, slick, currentSlide, nextSlide) {

                var slideIndexToPreload = (nextSlide > currentSlide ? nextSlide : currentSlide - 1) ;
                var slideToPreload = $(slick.$slides.get(slideIndexToPreload ));
                var slideToPreloadPicture = slideToPreload.find('picture');

                $(slideToPreloadPicture).each(function () {
                    var slideToPreloadSourceSets = $(this).find('source');
                    var slideToPreloadImg = $(this).find('img');
                    if(slideToPreloadImg.data('load') === 'no-loaded'){

                        for (var i=0; i < slideToPreloadSourceSets.length; i++) {
                            if(slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset') != null) {
                                slideToPreloadSourceSets[i].setAttribute('srcset',slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset'));
                                slideToPreloadSourceSets[i].removeAttribute('data-lazy-srcset');
                            }
                        }

                        for (var i=0; i < slideToPreloadImg.length; i++){
                            if(slideToPreloadImg[i].getAttribute('data-lazy-src')){
                                slideToPreloadImg[i].setAttribute('src',slideToPreloadImg[i].getAttribute('data-lazy-src'));
                                slideToPreloadImg[i].removeAttribute('data-lazy-src');
                            }
                        }

                        slideToPreloadImg .attr('data-load', 'loaded');
                    } else {
                        return;
                    }

                });
            });
            slideshow_container.find('.play-video').each(function(){
                var player = $(this);
                var video_id = $(this).data('video-id');
                player.click(function(e){
                    e.preventDefault();
                    domina_video_configuration(video_id);
                });
            });
        }
    }

    function domina_default_fancybox(){
        $('[data-fancybox]:not(.slick-cloned)').fancybox({
            buttons: [
                "close"
            ]
        });
    }

    function domina_additional_content(){
        var add_content_container = $('.additional-content'),
            thumb_container = add_content_container.find('.thumb-wrapper'),
            images = add_content_container.find('.thumb-wrapper').children();
        if(images.length > 1){
            thumb_container.on('init', function(){
                lazyLoadInstance.update();
            });

            thumb_container.not('.slick-initialized').slick({
                rows:0,
                slidesToShow:1,
                slidesToScroll:1,
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>'
            });
        }
    }

    function domina_additional_content_clsp(){
        var add_content_clsp_container = $('.additional-content-clsp'),
            items = add_content_clsp_container.find('.single-item');

        items.each(function(){

            var read_more_btn = $(this).find('.read-more'),
                thumbnail_container = $(this).find('.thumb'),
                excerpt_extra_wrapper = $(this).find('.excerpt-extra-wrap'),
                excerpt_extra = $(this).find('.excerpt-extra');

            read_more_btn.on('click', function(){

                if(!$(this).hasClass('open')){

                    $(this).addClass('open');
                    excerpt_extra_wrapper.css({
                        'height' : excerpt_extra.outerHeight(true)
                    });

                    $(this).html($(this).data('opentext'));

                    if(thumbnail_container.length > 0){
                        thumbnail_container.css({
                            height : thumbnail_container.height()
                        });
                    }

                } else{

                    $(this).removeClass('open');
                    excerpt_extra_wrapper.css({
                        'height' : 0
                    });

                    $(this).html($(this).data('closetext'));

                    excerpt_extra_wrapper.on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function(){
                        if(excerpt_extra_wrapper.height() === 0){
                            thumbnail_container.removeAttr('style');
                        }
                    });
                }

            });
        });
    }

    function domina_minigallery(){
        var minigallery_container = $('.minigallery-carousel'),
            items = minigallery_container.children(),
            hasCarousel = false;
        if(is_phone && items.length > 1){
            hasCarousel = true
        } else {
            if(is_mobile && items.length > 2){
                hasCarousel = true
            } else {
                if(items.length > 3){
                    hasCarousel = true
                }
            }
        }


        if(hasCarousel){
            minigallery_container.on('init', function(){
                lazyLoadInstance.update();
            });
            minigallery_container.not('slick-initialized').slick({
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            dots: false
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false
                        }
                    },
                ]
            });
        }
    }

    function domina_preview_pages_carousel_excerpt(){
        var prev_pages_container = $('.preview-pages-carousel'),
            items = prev_pages_container.children();

        if(prev_pages_container.length > 0){
            items.each(function(){
                var content_wrapper = $(this).find('.content-wrapper'),
                    content_wrap = $(this).find('.content-wrap');
                if(content_wrap.children().length > 0){
                    var totalHeight = 0;
                    content_wrap.children().each(function(){
                        totalHeight = totalHeight + $(this).outerHeight(true);
                    });
                }

                if(is_desktop){
                    if(totalHeight > content_wrapper.height()){
                        content_wrap.find('.excerpt').addClass('ellipsis');
                        content_wrap.find('.excerpt').dotdotdot();
                    }
                }
            });
        }
    }

    function domina_preview_pages_carousel(){
        var prev_pages_container = $('.preview-pages-carousel'),
            items = prev_pages_container.children();

        domina_preview_pages_carousel_excerpt();

        if(items.length > 1){
            prev_pages_container.on('init', function(){
                lazyLoadInstance.update();
            });
            prev_pages_container.not('slick-initialized').slick({
                rows: 0,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                speed: '1000',
                autoplaySpeed: '1500',
                waitForAnimate: false,
                autoplay: false,
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                dotsClass: 'slick-dots inverse',
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            arrows: false
                        }
                    },
                ]
            });
        }
    }

    function domina_siblings_carousel(){
        var siblings_carousel = $('.siblings-carousel'),
            items = siblings_carousel.children(),
            is_single_hotel_event = $('html').hasClass('single-hotel_event'),
            number_items,
            slickOptions,
            hasCarousel = false;

        if(is_single_hotel_event){
            number_items = 3;
            slickOptions = {
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                ]
            }

        } else {
            number_items = 2;
            slickOptions = {
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                ]
            }
        }

        if(is_phone && items.length > 1){
            hasCarousel = true;
        } else if(is_mobile){
            number_items = 2;
            if(items.length > number_items){
                hasCarousel = true;
            }
        } else{
            if(items.length > number_items){
                hasCarousel = true;
            }
        }

        if(siblings_carousel.length > 0 && hasCarousel){
            siblings_carousel.on('init', function(){
                lazyLoadInstance.update();
            });

            siblings_carousel.not('slick-initialized').slick(slickOptions);
        }
    }

    function domina_single_hotel_discover_more(){
        if($('html').hasClass('hotel-home')){
            var main_content_extra = $('.main-content-extra-container'),
                main_content = main_content_extra.find('.main-content-extra-content'),
                discover_more = $('.read-more-extra'),
                totalHeight = 0;

            //get total height elements
            main_content.children().each(function(){
                totalHeight += $(this).outerHeight(true);
            });

            if(main_content_extra.length > 0){
                discover_more.on('click', function(){
                    if(!$(this).hasClass('opened')){
                        $(this).addClass('opened');
                        main_content_extra.css({
                            'height': totalHeight,
                        });
                        $(this).html($(this).data('open-text'));
                    } else {
                        main_content_extra.css({
                            'height': 0,
                        });
                        $(this).html($(this).data('close-text'));
                        $(this).removeClass('opened');
                    }
                });
            }

        }
    }

    function domina_websdk_offers(){
        $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
        $('div[data-websdk]').each(function () {
            var container = $(this),
                model_name = container.data('websdk'),
                id = container.attr('id'),
                custom_template = container.data('websdk-template'),
                template_id, config, model, template, html;
            template_id = custom_template ? custom_template : '#' + id + '_template';
            template = $(template_id).text();
            if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined' /*&& typeof $fbsdk !== "undefined"*/) {
                config = websdk_config[id];
                $fbsdk.baseHost = config.baseHost;
                model = new $fbsdk[model_name](config.params);
                model.setAuthToken(config._authCode);
                model.load(function (error) {
                    var data, i;
                    if (!error) {
                        //YEAH, this happens when we have data and we're ready to use it!
                        data = this.getRawData();
                        if (model_name === 'Offers' && data.rates.length === 0) {
                            container.addClass('no-offers');
                        }
                        container.closest('.loading').addClass(' processed no-loading');

                        //Limit numbers of offers to display
                        if (typeof config.offers_number !== 'undefined' && config.offers_number <= data.rates.length) {
                            data.rates.length = parseInt(config.offers_number, 10);
                        }

                        //Exclude offers from websdk
                        if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers !== '') {
                            var temp_rates = [];
                            for (var i = 0; i < data.rates.length; i++) {
                                if (typeof data.rates[i] !== 'undefined') {
                                    if ($.inArray(data.rates[i].rate.name, config.excluded_offers) === -1) {
                                        // if is allowed
                                        temp_rates.push(data.rates[i]);
                                    }
                                }
                            }
                            // OVERRRIDE THE OBJ
                            data.rates = temp_rates;
                        }


                        for (var i = 0; i < data.rates.length; i++) {
                            data.rates[i].rate.title = data.rates[i].rate.title.replace(/(<([^>]+)>)/ig,'');
                            data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 120) + '...';
                        }

                        html = Mustache.render(template, data);
                        container.html(html);

                        //Update lazyload images
                        lazyLoadInstance.update();
                        domina_hotel_preview_offers_carousel();
                        domina_hotel_offers_carousel();

                    } else {
                        // in caso di errore
                        container.fadeOut();
                    }
                });
            }
        });
    }

    function domina_hotel_services(){
        var hotel_services_section = $('.hotel-preview-services'),
            hotel_services_list = hotel_services_section.find('.hotel-services-list');
        if(hotel_services_section.length > 0){
            if(hotel_services_list.length > 0){
                hotel_services_list.not('.slick-initialized').slick({
                    rows:0,
                    slidesToShow:2,
                    slidesToScroll:1,
                    centerMode:true,
                    centerPadding: '20px',
                    mobileFirst:true,
                    arrows: false,
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: "unslick"
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 0,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            }
        }
    }

    function domina_hotel_preview_offers_carousel(){
        if($('html').hasClass('homepage')){
            var preview_offers_container = $('.preview-offers'),
                children = preview_offers_container.children(),
                hasCarousel = false;

            if(is_mobile && children.length > 2){
                hasCarousel = true;
            } else if (is_phone && children.length > 1){
                hasCarousel = true;
            }

            if(hasCarousel){
                preview_offers_container.on('init', function(){
                    lazyLoadInstance.update();
                });
                preview_offers_container.not('.slick-initialized').slick({
                    rows:0,
                    slidesToShow:2,
                    slidesToScroll:1,
                    centerMode:true,
                    centerPadding: '20px',
                    mobileFirst:true,
                    arrows: false,
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: "unslick"
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 0,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]

                });
            }
        }
    }

    function domina_hotel_offers_carousel(){
        if($('html').hasClass('template-single-room') || $('html').hasClass('template-beach') || $('html').hasClass('template-diving-spa-casino')){
            var preview_offers_container = $('.preview-offers'),
                children = preview_offers_container.children(),
                hasCarousel = false;

            if(is_mobile && children.length > 2){
                hasCarousel = true;
            } else if (is_phone && children.length > 1){
                hasCarousel = true;
            } else {
                if(children.length > 3){
                    hasCarousel= true
                }
            }

            if(hasCarousel){
                preview_offers_container.on('init', function(){
                    lazyLoadInstance.update();
                });

                preview_offers_container.not('.slick-initialized').slick({
                    rows:0,
                    slidesToShow:3,
                    slidesToScroll:1,
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]

                });
            }

        }
    }

    function domina_hotel_offers_carousel_homepage(){
        if($('html').hasClass('homepage')){
            var preview_offers_container = $('.manual-offers-home'),
                children = preview_offers_container.children(),
                hasCarousel = false;

            if(is_mobile && children.length > 2){
                hasCarousel = true;
            } else if (is_phone && children.length > 1){
                hasCarousel = true;
            } else {
                if(children.length > 3){
                    hasCarousel= true
                }
            }

            if(hasCarousel){
                preview_offers_container.on('init', function(){
                    lazyLoadInstance.update();
                });

                preview_offers_container.not('.slick-initialized').slick({
                    rows:0,
                    slidesToShow:3,
                    slidesToScroll:2,
                    arrows: true,
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    responsive:[
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]

                });
            }

        }
    }

    function domina_hotel_restaurants(){
        if($('html').hasClass('template-restaurants')){
            var restaurants_container = $('.restaurants-section'),
                single_restaurant = restaurants_container.find('.single-item');

            single_restaurant.each(function(){
                var restaurant_gallery = $(this).find('.thumb-container'),
                    gallery_items = restaurant_gallery.children(),
                    read_more_btn = $(this).find('.read-more'),
                    excerpt_extra_wrapper = $(this).find('.excerpt-extra-wrap'),
                    excerpt_extra = $(this).find('.excerpt-extra');

                if(restaurant_gallery.length > 0 && gallery_items.length > 1){

                    restaurant_gallery.on('init', function(){
                        lazyLoadInstance.update();
                    });

                    restaurant_gallery.not('slick-initialized').slick({
                        rows: 0,
                        slidesToShow:1,
                        slidesToScroll:1,
                        dots: true,
                        dotsClass: 'slick-dots inverse',
                        prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                        nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    });
                }

                read_more_btn.on('click', function(){

                    if(!$(this).hasClass('open')){
                        $(this).addClass('open');

                        if(restaurant_gallery.length > 0){
                            restaurant_gallery.css({
                                height : restaurant_gallery.height()
                            });
                        }

                        excerpt_extra_wrapper.css({
                            'height' : excerpt_extra.height()
                        });

                        $(this).html($(this).data('opentext'));

                    } else{

                        $(this).removeClass('open');

                        excerpt_extra_wrapper.css({
                            'height' : 0
                        });

                        $(this).html($(this).data('closetext'));

                        excerpt_extra_wrapper.on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function(){
                            if(excerpt_extra_wrapper.height() === 0){
                                restaurant_gallery.removeAttr('style');
                            }
                        });
                    }

                });
            });
        }
    }

    function domina_special_offers() {
        var special_offers_container = $('.all-travel-offers-container'),
            filter = $('.filter-travel-offers');
        if (special_offers_container.length > 0) {
            if (filter.length > 0) {
                special_offers_container.isotope({
                    itemSelector: '.single-offer',
                    layoutMode: 'fitRows',
                });

                $('.single-offer:visible').matchHeight();

                filter.on('change', function () {
                    var value = $(this).val() === 'all' ? "*" : $(this).val();
                    special_offers_container.isotope({filter: value});
                });
                special_offers_container.on( 'arrangeComplete', function( event, filteredItems ) {
                    $('.single-offer:visible').matchHeight();
                });

            }
        }
    }

    function domina_siblings_special_offers_carousel() {
        if ($('html').hasClass('single-post_offer')) {
            var siblings_carousel = $('.siblings-travel-offers-carousel'),
                items = siblings_carousel.children(),
                hasCarousel = false;

            if (is_phone && items.length > 1) {
                hasCarousel = true;
            } else {
                if (items.length > 3) {
                    hasCarousel = true;
                }
            }

            if (siblings_carousel.length > 0 && hasCarousel) {
                siblings_carousel.on('init', function () {
                    lazyLoadInstance.update();
                });

                siblings_carousel.not('slick-initialized').slick({
                    rows: 0,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1
                            }
                        },
                    ]
                });
            }
        }
    }

    function domina_hotel_initialize_map(){
        if($('html').hasClass('template-location') && !$('html').hasClass('gmaps_embedded_enabled')){
            if (typeof(div) === "undefined") div = "map_canvas";
            if (typeof(directionsService) === "undefined") directionsService = new google.maps.DirectionsService();
            if (typeof(bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();
            directionsDisplay = new google.maps.DirectionsRenderer();

            var marker, i;

            var infoWindow = new google.maps.InfoWindow({
                'maxWidth': 500,
                'maxHeight': 400,
            });

            center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

            map = new google.maps.Map(document.getElementById(div), {
                // scroll wheel
                scrollwheel: false,
                //zoom
                zoom: map_config.map_zoom,
                zoomControl: map_config.map_zoom_control,
                zoomControlOptions: {
                    position: google.maps.ControlPosition[map_config.zoom_control_position]
                },
                //position
                center: new google.maps.LatLng(center[0], center[1]),
                //map type
                mapTypeId: google.maps.MapTypeId[map_config.map_type],
                mapTypeControl: map_config.map_type_control,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    style: google.maps.MapTypeControlStyle.DEFAULT
                },
                // default pan control
                panControl: false,
                panControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // scale control - image with the scale index (m,km,etc)
                scaleControl: false,
                scaleControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // 	streetview controls
                streetViewControl: map_config.map_streetview_control,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                }

            });

            var styles = [];
            map.setOptions({styles: styles});

            for (i = 0; i < map_config.markers.length; i++) {

                if (i === 0) {
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(map_config.markers[0].latitude, map_config.markers[0].longitude),
                        map: map,
                        icon: map_config.markers[0].icon_hotel,
                        zIndex:99999999
                    });

                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            infoWindow.setContent(map_config.markers[i].html);
                            infoWindow.open(map, marker);
                        };
                    })(marker, i));
                    $(document).on('click', '.close', function(event){
                        infoWindow.close(map, marker);
                    });
                }
            }
        }
    }

    function domina_hotel_calcRoute(div) {
        if (!$('html').hasClass('gmaps_embedded_enabled')) {
            var start = document.getElementById('itineraryFrom').value,
                coords =  map_config.hotel_coords.split(','),
                end = '' + coords[0] + ',' + coords[1],
                error_message_from = $('.itinerary-section .from .error-message');
            if ($('html').hasClass('template-location-poi')){
                error_message_from = $('.itinerary-section .error-message');
            }

            // remove previous message if present
            if ($('.itineraryPanelWrapper').length > 0) {
                $('.itineraryPanelWrapper').remove();
            }
            // delete previous results
            if (typeof directionsDisplay === 'undefined') {
                directionsDisplay = new google.maps.DirectionsRenderer();
            }
            //reset close button display
            $(".close_map.bottom").css("display", "inherit");

            directionsDisplay.setPanel(null);
            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(document.getElementById('map_directions_content'));

            if (typeof start === 'undefined' || start === '') {
                start = $('input[name=itineraryFrom]').val();
            }

            if (start === '') {
                error_message_from.fadeIn();
                error_message_from.html(empty_message_html);
                error_message_from.closest('.map-box-info-container').css({
                    height : error_message_from.closest('.map-box-info-content').outerHeight()
                });
                directionsDisplay.setPanel(null);
                directionsDisplay.setMap(null);
                if(map_config.markers.length === 0){
                    map.setZoom(map_config.map_zoom);
                }
            } else {
                var request = {
                    origin: start,
                    destination: end,
                    travelMode: google.maps.TravelMode[travel_mode]
                };

                directionsService.route(request, function (response, status) {
                    if (status === google.maps.DirectionsStatus.OK) {
                        directionsDisplay.setDirections(response);
                        error_message_from.fadeOut();
                        error_message_from.closest('.map-box-info-container').css({
                            height : error_message_from.closest('.map-box-info-content').outerHeight()
                        });
                        $('#map_directions').fadeIn();
                        $('#map_directions_content .directions-message').html(ok_message_html);
                    }
                    else if (status === google.maps.DirectionsStatus.NOT_FOUND) {
                        error_message_from.fadeIn();
                        error_message_from.html(not_found_message_html);
                        error_message_from.closest('.map-box-info-container').css({
                            height : error_message_from.closest('.map-box-info-content').outerHeight()
                        });
                        directionsDisplay.setPanel(null);
                        directionsDisplay.setMap(null);
                        if(map_config.markers.length === 0){
                            map.setZoom(map_config.map_zoom);
                        }
                        map.setCenter(google.maps.LatLng(coords[0],coords[1]));
                        $(".close_map.bottom").css("display", "none");
                    }
                    else {
                        error_message_from.fadeIn();
                        error_message_from.html(err_message_html);
                        error_message_from.closest('.map-box-info-container').css({
                            height : error_message_from.closest('.map-box-info-content').outerHeight()
                        });
                        directionsDisplay.setPanel(null);
                        directionsDisplay.setMap(null);
                        if(map_config.markers.length === 0){
                            map.setZoom(map_config.map_zoom);
                        }
                        map.setCenter(google.maps.LatLng(coords[0],coords[1]));
                        $(".close_map.bottom").css("display", "none");
                    }
                });
            }

            //this part handle the close_map button
            $('.close-map').click(function () {
                $('#map_directions').fadeOut(function () {
                    directionsDisplay.setPanel(null);
                    directionsDisplay.setMap(null);
                    if ($('html').hasClass('template-location')){
                        map.setZoom(map_config.map_zoom);
                    } else {
                        setTimeout( function() {
                            map.fitBounds(bounds);
                        }, 100 );
                    }
                    map.setCenter(new google.maps.LatLng(coords[0],coords[1]));
                });
                $('.itineraryPanelWrapper').remove();
            });
        }
    }

    function domina_hotel_itinerary_manager(){
        if($('html').hasClass('template-location') || $('html').hasClass('template-location-poi') && !$('html').hasClass('gmaps_embedded_enabled')){
            window.travel_mode = 'DRIVING';
            $('.travel-mode a').on('click',function() {
                travel_mode = $(this).data('travel');
                $(this).addClass('active').siblings().removeClass('active');

                if(document.getElementsByName("itineraryFrom")[0].value.length > 0){
                    $('#itinerarySubmit').trigger('click');
                }
            });
            $('#itinerarySubmit').on('click', function () {
                domina_hotel_calcRoute('map_canvas');
            });
        }
    }

    function domina_map_info_box(){
        var map_info_container = $('.map-box-info'),
            map_info_section = map_info_container.find('.map-info-section');
        if(map_info_container.length > 0){
            if(map_info_section.length > 0){
                map_info_section.first().addClass('opened');
                map_info_section.first().find('.map-box-info-container').css({
                    height:  map_info_section.first().find('.map-box-info-content').outerHeight()
                });
                map_info_section.each(function(){
                    var section = $(this),
                        title_section = section.find('.title-section'),
                        box_info_container = section.find('.map-box-info-container'),
                        box_info_content = section.find('.map-box-info-content');

                    title_section.on('click', function(){
                        var siblings = section.siblings();

                        if(!$(this).hasClass('opened')){
                            section.addClass('opened');
                            box_info_container.css({
                                height: box_info_content.outerHeight()
                            });
                        } else {
                            section.removeClass('opened');
                            box_info_container.css({
                                height: 0
                            });
                        }

                        if(siblings.hasClass('opened')){
                            siblings.find('.map-box-info-container').css({
                                height: 0
                            });
                            siblings.removeClass('opened');
                        }
                    });
                });
            }
        }
    }
    //map_poi
    function domina_initialize_map_poi(divForMap) {
        if($('html').hasClass('template-location-poi') && !$('html').hasClass('gmaps_embedded_enabled')) {

            if (typeof(div) === "undefined") div = "map_canvas";
            if (typeof(directionsService) === "undefined") directionsService = new google.maps.DirectionsService();
            if (typeof(bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();
            directionsDisplay = new google.maps.DirectionsRenderer();

            var marker, i;

            var infoWindow = new google.maps.InfoWindow({
                'maxWidth': 500,
                'maxHeight': 400,
            });

            center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

            map = new google.maps.Map(document.getElementById(div), {
                // scroll wheel
                scrollwheel: false,
                //zoom
                zoom: map_config.map_zoom,
                zoomControl: map_config.map_zoom_control,
                zoomControlOptions: {
                    position: google.maps.ControlPosition[map_config.zoom_control_position]
                },
                //position
                center: new google.maps.LatLng(center[0], center[1]),
                //map type
                mapTypeId: google.maps.MapTypeId[map_config.map_type],
                mapTypeControl: map_config.map_type_control,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    style: google.maps.MapTypeControlStyle.DEFAULT
                },
                // default pan control
                panControl: false,
                panControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // scale control - image with the scale index (m,km,etc)
                scaleControl: false,
                scaleControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // 	streetview controls
                streetViewControl: map_config.map_streetview_control,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                }

            });

            var styles = [];
            map.setOptions({styles: styles});

            for (i = 0; i < map_config.markers.length; i++) {
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(map_config.markers[i].latitude, map_config.markers[i].longitude),
                    map: map,
                    icon: map_config.markers[i].icon_marker,
                    zIndex: (map_config.markers[i].slug === 'hotel') ? 99999999 : 1000
                });
                markers.push(marker);

                google.maps.event.addListener(marker, 'click', (function (marker, i) {
                    return function () {
                        infoWindow.setContent(map_config.markers[i].html);
                        infoWindow.open(map, marker);
                    };
                })(marker, i));
                if(map_config.markers.length > 1) {
                    bounds.extend(marker.position);
                    map.fitBounds(bounds);
                } else {
                    var coord = map_config.hotel_coords.split(',');
                    map.setCenter(new google.maps.LatLng(coord[0],coord[1]));
                    directionsService = new google.maps.DirectionsService(), directionsDisplay = new google.maps.DirectionsRenderer({
                        suppressMarkers: false,
                        markerOptions: {zIndex: 10}
                    });
                }
            }

            var styles = [
                {
                    "featureType": "all",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "saturation": "-100"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 40
                        },
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#4d6059"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#4d6059"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#4d6059"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#4d6059"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#4d6059"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#7f8d89"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#2b3638"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2b3638"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#24282b"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#24282b"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ];
            map.setOptions({styles: styles});

            google.maps.event.addDomListener(window, "resize", function () {
                google.maps.event.trigger(map, "resize");
                if (map_config.markers.length > 1) {
                    map.fitBounds(bounds);
                } else {
                    map.setCenter(new google.maps.LatLng(coord[0],coord[1]));
                }
            });
        }
    }

    function domina_handler_map(){
        if($('html').hasClass('template-location') || $('html').hasClass('template-location-poi') && !$('html').hasClass('gmaps_embedded_enabled') ){
            var map_container = document.querySelector('.map-container');
            observer = new IntersectionObserver(function(entry, observer){
                if(entry[0].intersectionRatio > 0){
                    if ($('html').hasClass('template-location')){
                        domina_hotel_initialize_map();
                    } else {
                        domina_initialize_map_poi();
                    }
                    observer.unobserve(map_container);
                }
            });
            observer.observe(map_container);
        }
    }

    function domina_hotel_photogallery(){
        if($('html').hasClass('template-photogallery')){
            var photogallery_section = $('.photogallery-section'),
                filter = photogallery_section.find('.filter-photogallery'),
                photogallery_container = photogallery_section.find('.photogallery-container');
            if(filter.length > 0){
                photogallery_container.isotope({
                    itemSelector: '.single-image',
                    layoutMode: 'fitRows',
                });

                filter.on('change', function(){
                    var value = $(this).val() === 'all' ? "*" : $(this).val();
                    photogallery_container.isotope({ filter: value });
                });
            }
        }
    }

    function domina_hotel_excursions(){
        if($('html').hasClass('template-excursions')){
            var excursions_container = $('.excursions-container'),
                filter = $('.filter-excursions'),
                items = excursions_container.children();
            if(excursions_container.length > 0){
                items.each(function(){
                    var element = $(this),
                        featured_image = $(this).find('.thumb'),
                        read_more_btn = $(this).find('.read-more-extra'),
                        main_extra_content_wrapper = $(this).find('.content-extra-wrapper'),
                        main_extra_content = $(this).find('.extra-content');
                    if(read_more_btn.length > 0){
                        read_more_btn.on('click', function(){
                            if(!element.hasClass('opened')){
                                element.addClass('opened');
                                featured_image.css({
                                    'height': featured_image.height()
                                });
                                main_extra_content_wrapper.css({
                                    'height' : main_extra_content.height()
                                });


                                if(filter.length > 0){
                                    excursions_container.isotope('layout');
                                    setTimeout( function() {
                                        excursions_container.isotope('layout');
                                    }, 400 );
                                }


                                main_extra_content_wrapper.on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function(){
                                    if(main_extra_content_wrapper.height() > 0){
                                        read_more_btn.html(read_more_btn.data('open-text'));
                                    }
                                });


                            } else{
                                main_extra_content_wrapper.css({
                                    'height' : 0
                                });
                                element.removeClass('opened');
                                main_extra_content_wrapper.on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function(){
                                    if(main_extra_content_wrapper.height() === 0){
                                        read_more_btn.html(read_more_btn.data('close-text'));
                                        featured_image.removeAttr('style');
                                    }
                                });

                                if(filter.length > 0){
                                    excursions_container.isotope('layout');
                                    setTimeout( function() {
                                        excursions_container.isotope('layout');
                                    }, 400 );
                                }
                            }
                        });
                    }
                });
            }

            if(filter.length > 0){
                excursions_container.isotope({
                    itemSelector: '.single-item',
                    layoutMode: 'fitRows',
                });

                filter.on('change', function(){
                    var value = $(this).val() === 'all' ? "*" : $(this).val();
                    excursions_container.isotope({ filter: value });
                });
            }

        }
    }

    function domina_hotel_excursions_isotope(){
        if($('html').hasClass('template-excursions')){
            var excursions_container = $('.excursions-container'),
                filter = $('.filter-excursions');
            if(excursions_container.length > 0 && filter.length > 0){
                excursions_container.isotope('layout');
            }
        }
    }

    function domina_beach_carousel_events_carousel(){
        if($('html').hasClass('template-beach') || $('html').hasClass('template-diving-spa-casino')){
            var carousel_container = $('.preview-events-offers-container'),
                children = carousel_container.children(),
                hasCarousel = false;

            if(children.length > 1 && is_phone){
                hasCarousel = true;
            } else{
                if(children.length > 2 && is_mobile){
                    hasCarousel = true;
                } else{
                    if(children.length > 3){
                        hasCarousel = true;
                    }
                }
            }

            if(hasCarousel){
                carousel_container.on('init', function (){
                    lazyLoadInstance.update();
                });
                carousel_container.slick({
                    rows: 0,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    responsive:[
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ]
                });
            }

        }
    }

    function domina_clonable_carousel(){
        if($('html').hasClass('template-diving-spa-casino')){
            var clonable_section = $('.clonable-carousel-section'),
                single_section = clonable_section.find('.single-section');
            if(clonable_section.length > 0){
                if(single_section.length > 0){
                    single_section.each(function(){
                        var navigation = $(this).find('.slider-navigation');
                        var carousel = $(this).find('.slider-children');
                        if(navigation.length > 0 && carousel.length > 0){
                            carousel.on('init', function(){
                                lazyLoadInstance.update();
                            });
                            navigation.not('.slick-initialized').slick({
                                rows: 0,
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                focusOnSelect: true,
                                arrows: true,
                                waitForAnimate: false,
                                asNavFor: carousel,
                                prevArrow: '<a href="javascript:;" class="arrow simple prev slick-prev" aria-label=""><span></span></a>',
                                nextArrow: '<a href="javascript:;" class="arrow simple next slick-next" aria-label=""><span></span></a>',
                                responsive: [{
                                    breakpoint: 992,
                                    settings: {
                                        slidesToShow: 3,
                                    }
                                }, {
                                    breakpoint: 767,
                                    settings: {
                                        slidesToShow: 3,
                                    }
                                }, {
                                    breakpoint: 420,
                                    settings: {
                                        slidesToShow: 1,
                                    }
                                }]
                            });

                            carousel.not('.slick-initialized').slick({
                                rows: 0,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                waitForAnimate: false,
                                autoplaySpeed: 1500,
                                speed: 1250,
                                cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
                                asNavFor: navigation,
                            });
                        }
                    });
                }
            }
        }
    }

    function domina_news_slideshow() {
        if ($('html').hasClass('single-post')) {
            var news_slideshow_container = $('.news-slideshow');
            if (news_slideshow_container.length > 0) {
                news_slideshow_container.not('.slick-initialized').slick({
                    rows: 0,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    dots: true,
                    dotsClass: 'slick-dots inverse'
                });
            }
        }
    }

    function domina_siblings_news() {
        if ($('html').hasClass('single-post')) {
            var siblings_carousel = $('.siblings-news-carousel'),
                items = siblings_carousel.children(),
                hasCarousel = false;

            if (is_phone && items.length > 1) {
                hasCarousel = true;
            } else {
                if (items.length > 3) {
                    hasCarousel = true;
                }
            }

            if (siblings_carousel.length > 0 && hasCarousel) {
                siblings_carousel.on('init', function () {
                    lazyLoadInstance.update();
                });

                siblings_carousel.not('slick-initialized').slick({
                    rows: 0,
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    dots: true,
                    prevArrow: '<a href="javascript:;" class="arrow rounded-half prev slick-prev" aria-label=""><span></span></a>',
                    nextArrow: '<a href="javascript:;" class="arrow rounded-half next slick-next" aria-label=""><span></span></a>',
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            }
                        },
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: false
                            }
                        }
                    ]
                });
            }
        }
    }

    function domina_hamburger_mobile(){
        var hamburger_mobile = $('.open_mobile_menu');
        if(hamburger_mobile.length > 0){
            hamburger_mobile.on('click', function(){
                $(this).toggleClass('open');
                setTimeout(function () {
                    $('.sidebar-mobile-menu').toggleClass('visible');
                }, 300);
                $('html').toggleClass('no-scroll');
            });

            if($('#mobile-hotel-primary-menu').length > 0){
                var submenus = $('#mobile-hotel-primary-menu').find('.menu-item-has-children');
                submenus.each(function(){
                    var single_item = $(this),
                    dropdown = single_item.find('.dropdown'),
                    submenu_wrap = single_item.find('.sub-menu-wrap'),
                    submenu = submenu_wrap.find('.sub-menu');

                    dropdown.on('click',function () {
                        if(!single_item.hasClass('sub-menu-opened')){
                            single_item.addClass('sub-menu-opened');
                            if(submenu_wrap.length > 0){
                                submenu_wrap.css({
                                    height: submenu.outerHeight(true)
                                });
                            }
                        } else{
                            single_item.removeClass('sub-menu-opened');
                            submenu_wrap.css({
                                height: 0
                            });
                        }
                    });
                });
            }
        }
    }

    function domina_hamburger_hotel(){
        if(!is_mobile){
            var hamburger_full_menu =  $('.hamburger-menu.full-menu'),
                hamburger_hotel = $('.hamburger-menu.primary-menu'),
                sidebar_menu_full = $('.menu-sidebar-wrap.full-menu'),
                sidebar_menu_primary = $('.menu-sidebar-wrap.primary-menu');

            if(hamburger_full_menu.length > 0){
                var close_btn = $('.close-container');
                hamburger_full_menu.on('click', function(){
                    sidebar_menu_full.addClass('opened');
                    setTimeout(function () {
                        sidebar_menu_full.addClass('visible');
                    }, 300);
                    $('html').addClass('no-scroll');
                    if(sidebar_menu_full.hasClass('opened')){
                        var sidebar_inner_content = $('.menu-sidebar-inner-content');
                        var sidebar_hotel_primary_menu = $('#sidebar-hotel-primary-menu .menu');

                        if(sidebar_hotel_primary_menu.outerHeight() > sidebar_inner_content.outerHeight()){
                            var Scrollbar = window.Scrollbar;
                            var options = {
                                alwaysShowTracks: true,
                            };
                            Scrollbar.init(document.querySelector('.menu-sidebar-inner-content'), options);
                        }
                    }
                    window.addEventListener('resize', function(){
                        if($('.menu-sidebar-wrap.full-menu').hasClass('opened')){
                            var sidebar_inner_content = $('.menu-sidebar-inner-content');
                            var sidebar_hotel_primary_menu = $('#sidebar-hotel-full-menu .menu');

                            if(sidebar_hotel_primary_menu.outerHeight() > sidebar_inner_content.outerHeight()){
                                var Scrollbar = window.Scrollbar;
                                var options = {
                                    alwaysShowTracks: true,
                                };
                                Scrollbar.init(document.querySelector('.menu-sidebar-inner-content'), options);
                            }
                        }
                    });
                });

                if($('#sidebar-hotel-full-menu').length > 0){
                    var items = $('#sidebar-hotel-full-menu .menu').find('li');

                    items.each(function(){
                        var item = $(this);
                        if(item.hasClass('menu-item-has-children')){
                            var dropdown = item.find('.dropdown');

                            dropdown.on('click', function (){
                                if(!item.hasClass('sub-menu-opened')){
                                    item.addClass('sub-menu-opened');
                                } else{
                                    item.removeClass('sub-menu-opened');
                                }
                            });
                        }
                    });
                }

                close_btn.on('click', function(){
                    sidebar_menu_full.removeClass('visible');
                    setTimeout(function () {
                        sidebar_menu_full.removeClass('opened');
                    }, 300);

                    $('html').removeClass('no-scroll');
                });
            }

            if(hamburger_hotel.length > 0){
                var close_btn = $('.close-container');
                hamburger_hotel.on('click', function(){
                    sidebar_menu_primary.addClass('opened');
                    setTimeout(function () {
                        sidebar_menu_primary.addClass('visible');
                    }, 300);
                    $('html').addClass('no-scroll');

                    if(sidebar_menu_primary.hasClass('opened')){
                        var sidebar_inner_content = $('.menu-sidebar-inner-content');
                        var sidebar_hotel_primary_menu = $('#sidebar-hotel-primary-menu .menu');

                        if(sidebar_hotel_primary_menu.outerHeight() > sidebar_inner_content.outerHeight()){
                            var Scrollbar = window.Scrollbar;
                            var options = {
                                alwaysShowTracks: true,
                            };
                            Scrollbar.init(document.querySelector('.menu-sidebar-inner-content'), options);
                        }
                    }
                    window.addEventListener('resize', function(){
                        if(sidebar_menu_primary.hasClass('opened')){
                            var sidebar_inner_content = $('.menu-sidebar-inner-content');
                            var sidebar_hotel_primary_menu = $('#sidebar-hotel-primary-menu .menu');

                            if(sidebar_hotel_primary_menu.outerHeight() > sidebar_inner_content.outerHeight()){
                                var Scrollbar = window.Scrollbar;
                                var options = {
                                    alwaysShowTracks: true,
                                };
                                Scrollbar.init(document.querySelector('.menu-sidebar-inner-content'), options);
                            }
                        }
                    });
                });

                if($('#sidebar-hotel-primary-menu').length > 0){
                    var items = $('#sidebar-hotel-primary-menu .menu').find('li');

                    items.each(function(){
                        var item = $(this);
                        if(item.hasClass('menu-item-has-children')){
                            var dropdown = item.find('.dropdown');

                            dropdown.on('click', function (){
                                if(!item.hasClass('sub-menu-opened')){
                                    item.addClass('sub-menu-opened');
                                } else{
                                    item.removeClass('sub-menu-opened');
                                }
                            });
                        }
                    });
                }

                close_btn.on('click', function(){
                    sidebar_menu_primary.removeClass('visible');
                    setTimeout(function () {
                        sidebar_menu_primary.removeClass('opened');
                    }, 300);

                    $('html').removeClass('no-scroll');
                });
            }
        }
    }

    function domina_dynamic_calendar(){
        var btns = $('#open_dyna_cal, #open_mobile_dyna_cal');
        var calendar_wrapper = $('#dyncal_wrapper');
        btns.click(function () {
            if (!calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.addClass('visible');
                $('html').addClass('no-scroll');
            }
        });

        $('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');
                $('html').removeClass('no-scroll');

                //reset calendar
                $fastbooking.reset_calendar();

                //preselect the current property
                if(current_hotel_index.length > 0 && select_hotel != null){
                    select_hotel.value = current_hotel_index;
                    select_hotel.dispatchEvent(new Event('change'));
                }
            }
        });
    }

    //window load
    $(window).on('load',function () {
        domina_refresh_size_queries();
        domina_special_offers();
    });

    //window resize
    $(window).resize(function () {
        domina_refresh_size_queries();
        domina_handler_map();
        domina_preview_pages_carousel_excerpt();
    });

    //Dom ready
    $(document).ready(function () {
        domina_refresh_size_queries();
        domina_slideshow();
        domina_default_fancybox();
        domina_additional_content();
        domina_additional_content_clsp();
        domina_minigallery();
        domina_preview_pages_carousel();
        domina_siblings_carousel();
        domina_single_hotel_discover_more();
        domina_hotel_services();
        domina_websdk_offers();
        domina_hotel_offers_carousel_homepage();
        domina_siblings_special_offers_carousel();
        domina_hotel_restaurants();
        domina_handler_map();
        domina_hotel_itinerary_manager();
        domina_map_info_box();
        domina_hotel_photogallery();
        domina_hotel_excursions();
        domina_beach_carousel_events_carousel();
        domina_clonable_carousel();
        domina_news_slideshow();
        domina_siblings_news();
        domina_hamburger_mobile();
        domina_hamburger_hotel();
        domina_dynamic_calendar();
    });
})(jQuery);